import React, { useState } from "react";
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { Row, Col, Table } from 'react-bootstrap';
import moment from 'moment';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
	page: {
		backgroundColor: "white",
		color: "black",
		fontFamily: "Helvetica",
		fontSize: "12px",
		paddingTop: "40px",
		paddingBottom: "40px",
		paddingRight: "20px",
		paddingLeft: "20px"
	},
	section: {
		margin: 10,
		padding: 10
	},
	viewer: {
		width: window.innerWidth,
		height: window.innerHeight
	},
	row: {
		flexDirection: 'row',
		borderBottomColor: '#bff0fd',
		borderBottomWidth: 1,
		alignItems: 'center',
		height: 24,
		fontStyle: 'bold',
	},
	txid: {
		width: '50px',
		textAlign: 'center',
	},
	item: {
		width: '50px',
		paddingLeft: 5,
		paddingRight: 5,
		textAlign: 'right',
	},
	description: {
		width: '45%',
		textAlign: 'left',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		paddingLeft: 8,
	},
	date: {
		width: '15%',
		borderRightColor: borderColor,
		borderRightWidth: 1,
		textAlign: 'center',
		paddingRight: 8,
	},
	category: {
		width: '70px',
		borderRightColor: borderColor,
		textAlign: 'left',
	},
	amount: {
		width: '10%',
		textAlign: 'right',
		paddingRight: 5,
	},
	total: {
		width: '200px',
		textAlign: 'right',
		paddingRight: '10px',
		fontSize: '20px',
	},
	table: {

	}
});

const PdfDocument = (value) => {
	const [show, setShow] = useState(false);
	const data = value.data.transactions;
	const total = value.data.totalSum;
	//console.log(value);
	const showPdf = () => {
		//console.log(data);
		//console.log(total)
		return (
			<div>
				<PDFViewer style={styles.viewer}>
					{/* Start of the document*/}
					<Document>
						{/*render a single page*/}
						<Page size="A4" style={styles.page}>
							<View style={styles.table}>
								<View style={styles.row}>
									<Text style={styles.item}>#</Text>
									<Text style={styles.txid}>TX ID</Text>
									<Text style={styles.date}>DATE</Text>
									<Text style={styles.category}>CATEGORY</Text>
									<Text style={styles.description}>DESCRIPTION</Text>
									<Text style={styles.amount}>AMOUNT</Text>
								</View>
								{data.map((item, index) => (
									<View style={styles.row} key={index}>
										<Text style={styles.item}>{index + 1} </Text>
										<Text style={styles.txid}>{String(item.id).padStart(5, '0')} </Text>
										<Text style={styles.date}>{moment(item.date).format("YYYY-MM-DD")} </Text>
										<Text style={styles.category}>{item.catname === null ? "-" : item.catname} </Text>
										<Text style={styles.description}>{item.description} </Text>
										<Text style={styles.amount}>{new Intl.NumberFormat('en-US').format(item.amount)} </Text>
									</View>
								)
								)}
							</View>
							<View style={styles.section}>
								<Text style={styles.total}>TOTAL (NTD): {new Intl.NumberFormat('en-US').format(total)}</Text>
							</View>
						</Page>
					</Document>
				</PDFViewer>
			</div >
		);
	}

	const exportPdf = () => (


		< Document >
			{/*render a single page*/}
			< Page size="A4" style={styles.page} >
				<View style={styles.table}>
					<View style={styles.row}>
						<Text style={styles.item}>#</Text>
						<Text style={styles.txid}>TX ID</Text>
						<Text style={styles.date}>DATE</Text>
						<Text style={styles.category}>CATEGORY</Text>
						<Text style={styles.description}>DESCRIPTION</Text>
						<Text style={styles.amount}>AMOUNT</Text>
					</View>
					{data.map((item, index) => (
						<View style={styles.row} key={index}>
							<Text style={styles.item}>{index + 1} </Text>
							<Text style={styles.txid}>{String(item.id).padStart(5, '0')} </Text>
							<Text style={styles.date}>{moment(item.date).format("YYYY-MM-DD")} </Text>
							<Text style={styles.category}>{item.catname} </Text>
							<Text style={styles.description}>{item.description} </Text>
							<Text style={styles.amount}>{new Intl.NumberFormat('en-US').format(item.amount)} </Text>
						</View>
					)
					)}
				</View>
				<View style={styles.section}>
					<Text style={styles.total}>TOTAL (NTD): {new Intl.NumberFormat('en-US').format(total)}</Text>
				</View>
			</Page >
		</Document >


	)

	return (
		<>
			{/* 	<PDFDownloadLink document={<exportPdf />} fileName="report.pdf">
				{({ blob, url, loading, error }) =>
					loading ? 'Loading document...' : 'Download now!'
				}
			</PDFDownloadLink> */}
			<button onClick={() => {
				//console.log('showPDF');
				show ? setShow(false) : setShow(true);
			}
			}>Show PDF</button>

			{show ? showPdf() : null}
		</>
	);
};

export const MyDoc = (value) => {
	const data = value.data.transactions;
	const total = value.data.totalSum;
	return (
		<Document>
			{/*render a single page*/}
			<Page size="A4" style={styles.page}>
				<View style={styles.table}>
					<View style={styles.row}>
						<Text style={styles.item}>#</Text>
						<Text style={styles.txid}>TX ID</Text>
						<Text style={styles.date}>DATE</Text>
						<Text style={styles.category}>CATEGORY</Text>
						<Text style={styles.description}>DESCRIPTION</Text>
						<Text style={styles.amount}>AMOUNT</Text>
					</View>
					{data.map((item, index) => (
						<View style={styles.row} key={index}>
							<Text style={styles.item}>{index + 1} </Text>
							<Text style={styles.txid}>{String(item.id).padStart(5, '0')} </Text>
							<Text style={styles.date}>{moment(item.date).format("YYYY-MM-DD")} </Text>
							<Text style={styles.category}>{item.catname} </Text>
							<Text style={styles.description}>{item.description} </Text>
							<Text style={styles.amount}>{new Intl.NumberFormat('en-US').format(item.amount)} </Text>
						</View>
					)
					)}
				</View>
				<View style={styles.section}>
					<Text style={styles.total}>TOTAL (NTD): {new Intl.NumberFormat('en-US').format(total)}</Text>
				</View>
			</Page>
		</Document>
	)
};

export default PdfDocument;