import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Col } from "react-bootstrap";
import { RiLoginBoxLine, RiLogoutBoxRLine, RiDashboardLine } from 'react-icons/ri';
import { AiOutlineTransaction, AiOutlineSetting } from 'react-icons/ai';
import { BiUserCircle } from 'react-icons/bi';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../api/AuthContext';

const MenuBar = () => {
	const { setAuth, user, isAdmin } = useAuth();
	const navigate = useNavigate();
	const [isLogin, setIsLogin] = useState(false);

	useEffect(() => {
		checkAuth();
		/* console.log('isadmin:', user, isAdmin) */
	}, [isLogin, user])

	const checkAuth = () => {
		//setUser(user)
		if (user != null) {
			setIsLogin(true);
		} else {
			setIsLogin(false);
		}
	}

	const Logout = async () => {
		try {
			await axios.delete(process.env.REACT_APP_SERVER_IPADDR + '/logout', {}, {
				withCredentials: true,
				credentials: 'include'
			});
			setIsLogin(false);
			setAuth(false);
			navigate("/login");
		} catch (error) {
			console.log(error);
		}
	}

	const DefaultMenu = () => {
		return (
			<>
				<Nav.Link className='navbar-link' href='/login' data-toggle='tooltip' data-placement='bottom' title='Login'><RiLoginBoxLine className='menubar-link' /></Nav.Link>
			</>
		);
	}

	const LoggedInMenu = () => {
		return (
			<>
				<Nav.Link className='navbar-link' href="/userprofile" data-toggle="tooltip" data-placement="bottom" title="User">
					<BiUserCircle className='menubar-link' />
				</Nav.Link>
				<Nav.Link className='navbar-link' href="/dashboard" data-toggle="tooltip" data-placement="bottom" title="Dashboard"><RiDashboardLine
					className='menubar-link' /></Nav.Link>
				<Nav.Link className='navbar-link' href="/transaction" data-toggle="tooltip" data-placement="bottom" title="Transactions"><AiOutlineTransaction className='menubar-link' /></Nav.Link>
				{
					isAdmin != 0 ?
						<Nav.Link className='navbar-link' href="/settings" data-toggle="tooltip" data-placement="bottom" title="Setting">
							<AiOutlineSetting className='menubar-link' />
						</Nav.Link>
						: <></>
				}
				<Nav.Link className='navbar-link' data-toggle="tooltip" data-placement="bottom" title="Logout from App"><RiLogoutBoxRLine className='menubar-link' onClick={Logout} /></Nav.Link>
			</>
		)
	}

	return (
		<Navbar>
			<Container className='navbar-container' >
				<Navbar.Brand
					href="/">PeFi</Navbar.Brand>
				<div className="user-status">
					{user != null ? <label>&nbsp;{user}</label> : <></>}
					{isAdmin != 0 ? <label>(Admin)</label> : <label></label>}
				</div>
				<Nav>
					{isLogin ? <LoggedInMenu /> : <DefaultMenu />}
				</Nav>
			</Container>
		</Navbar>
	)
}

export default MenuBar