import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";

const AuthContext = createContext({
	auth: null,
	setAuth: () => { },
	user: null,
	isAdmin: 0
});

export const useAuth = () => useContext(AuthContext);

const serverapi = process.env.REACT_APP_SERVER_IPADDR + '/token';

const AuthProvider = ({ children }) => {
	const [auth, setAuth] = useState(null);
	const [user, setUser] = useState(null);
	const [isAdmin, setIsAdmin] = useState(0);

	useEffect(() => {
		const isAuth = async () => {
			try {
				const res = await axios.get(serverapi
				);
				//setToken(res.data.accessToken);
				const decoded = jwt_decode(res.data.accessToken, { withCredentials: true });
				setUser(decoded.name);
				setIsAdmin(decoded.admin);
				setAuth(true);
				/* console.log('auth:', decoded);
				console.log('isAdmin:', isAdmin) */
			} catch (error) {
				setUser(null);
				setIsAdmin(0);
			};
		};

		isAuth();
	}, [auth]);

	return (
		<AuthContext.Provider value={{ auth, setAuth, user, isAdmin }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;