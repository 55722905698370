import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import Select from 'react-select';
import { RiEdit2Line } from 'react-icons/ri';

import { Container, Row, Col, Form, InputGroup, Stack } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { AiOutlineConsoleSql } from 'react-icons/ai';
import { UpdateTransaction } from '../api/axios';

// to be replace by mysql call
const catOptions = [
	{ value: 1, label: 'Food' },
	{ value: 2, label: 'Transport' },
	{ value: 3, label: 'Household' },
	{ value: 4, label: 'Others' }
];

const TransactionEdit = (value) => {
	const [show, setShow] = useState(false);
	const [item, setItem] = useState([]);
	const [selectedDate, setSelectedDate] = useState(new Date(value.data.date));
	const [desc, setDesc] = useState(value.data.description);
	const [amount, setAmount] = useState(value.data.amount);
	const [category, setCategory] = useState(value.data.category_id);
	const [updateBtn, setUpdateBtn] = useState(true);

	const handleClose = () => {
		setShow(false);
		window.location.reload();
	}
	const handleShow = () => {
		setShow(true);
		setItem(value.data);
	}

	const handleUpdate = () => {
		item.date = moment(selectedDate).format("YYYY-MM-DD");
		item.description = desc;
		item.category_id = category;
		item.amount = amount;
		console.log('**** update', item);
		UpdateTransaction(item);
		setShow(false);
		window.location.reload();
	}

	const showItem = () => {
		return (
			<Container>
				<Row>
					<Col className='col-5'>
						<InputGroup>
							<InputGroup.Text className="">TX ID</InputGroup.Text>
							<Form.Control
								className="input-amount"
								aria-label="TXID"
								type="text"
								value={item.id}
								disabled={true}
							/>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Text className="mb-3">Date</InputGroup.Text>
							<DatePicker className="mb-3"
								format="dd-MM-yyyy"
								onChange={(date) => {
									setUpdateBtn(false);
									setSelectedDate(date);
								}}
								value={selectedDate}
								clearIcon={null}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<InputGroup className="mb-3">
						<InputGroup.Text>Description</InputGroup.Text>
						<Form.Control
							as="textarea"
							type="text"
							onFocus={() => setUpdateBtn(false)}
							value={desc}
							onChange={(e) => {
								const value = e.target.value;
								setDesc(value);
							}}
							placeholder="description of transaction"
						/>
					</InputGroup>
				</Row>
				<Row>
					<Col sm={7}>
						<InputGroup>
							<InputGroup.Text className="mb-3">Category</InputGroup.Text>
							<Select
								defaultValue={catOptions[0]}
								value={catOptions.find(o => o.value === category) || null}
								options={catOptions}
								onChange={(e) => {
									const value = e.value;
									console.log(value);
									setCategory(value);
									setUpdateBtn(false);
								}}
							/>
						</InputGroup>
					</Col>
					<Col sm={5}>
						<InputGroup>
							<InputGroup.Text>NTD</InputGroup.Text>
							<Form.Control
								className="input-amount"
								aria-label="Amount"
								type="number"
								value={amount}
								onFocus={() => setUpdateBtn(false)}
								onChange={(e) => {
									const value = e.target.value;
									setAmount(value);
								}}
							/>
						</InputGroup>
					</Col>
				</Row>
			</Container>
		)
	};

	return (
		<>
			< div className='d-flex justify-content-start' >
				<button className='transaction-edit-button' data-toggle='tooltip' data-placement='top' title='Edit' variant='primary' size='sm' onClick={(e) => handleShow(e)}><RiEdit2Line className='icon-button' /></button>
			</div >

			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Edit Transaction</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{showItem()}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleUpdate} disabled={updateBtn} variant="danger" size="sm">Update</Button>
					<Button variant="primary" size="sm" onClick={handleClose}>
						Close
					</Button>

				</Modal.Footer>
			</Modal>
		</>
	);
}

export default TransactionEdit;