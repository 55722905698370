import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const ErrorPage = () => {
	let navigate = useNavigate();
	return (
		<Container>
			<h2>Page not found</h2>
			<Button onClick={() => {
				navigate("/");
			}}>Back to Home</Button>
		</Container>
	)
}

export default ErrorPage