import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, InputGroup, Stack } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { SaveTransaction } from '../api/axios';

import { AiOutlinePlus } from 'react-icons/ai';


// to be replace by mysql call
const catOptions = [
	{ value: 1, label: 'Food' },
	{ value: 2, label: 'Transport' },
	{ value: 3, label: 'Household' },
	{ value: 4, label: 'Others' }
];


const AddTransaction = () => {

	const initialValue = {
		date: new Date(),
		category: null,
		description: "",
		amount: null
	};

	const [show, setShow] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [transaction, setTransaction] = useState(initialValue);
	const [categorySelect, setCategorySelect] = useState(null);
	const [amount, setAmount] = useState("");
	const [desc, setDesc] = useState("");
	const [saveBtn, setSaveBtn] = useState(true);
	const [clearBtn, setClearBtn] = useState(true);

	const handleClose = () => {
		setShow(false);
		window.location.reload();
	}
	const handleShow = () => {
		setSelectedDate(new Date());
		/* setTransaction(...transaction, [{ date: moment(selectedDate).format("YYYY-MM-DD") }]); */
		setTransaction({ ...transaction, date: moment(selectedDate).format("YYYY-MM-DD") });
		setShow(true);
	};

	const setDate = (e) => {
		console.log('setDate e:', e);
		const date = moment(e).format("YYYY-MM-DD");
		//const date = e;
		console.log('seDate', date);

		setSelectedDate(e);
		setTransaction({ ...transaction, date: date });
	}
	const SaveData = () => {
		console.log('**** SaveData');
		console.log(transaction);
		console.log('date:', transaction['date']);
		console.log('category:', transaction.category);
		console.log('description:', transaction.description);
		console.log('amount:', transaction.amount);
		console.log('amount', amount)
		console.log('desc', desc)
		console.log('category', categorySelect);
		console.log(JSON.stringify(transaction));
		SaveTransaction(transaction);
		ResetForm();
		console.log('**** EO SaveData');

	}

	const ResetForm = (e) => {
		console.log('---ResetForm---');
		const date = moment(new Date()).format("YYYY-MM-DD");
		console.log(date);
		setSelectedDate(new Date());
		//setDate(selectedDate);
		setTransaction(initialValue);
		setSaveBtn(true);
		setClearBtn(true);
		setAmount("");
		setDesc("");
		setCategorySelect(null);
		setTransaction({ ...transaction, date: date });
		console.log(JSON.stringify(transaction));
	}

	const addData = () => {
		return (
			<Container>
				<Form>
					<Row>
						<InputGroup as={Col}>
							<InputGroup.Text className="mb-3">Date</InputGroup.Text>
							<DatePicker className="mb-3"
								format="dd-MM-yyyy"
								onChange={(date) => {
									setDate(date);
								}}
								value={selectedDate}
								clearIcon={null}
							/>
						</InputGroup>
					</Row>
					<InputGroup className="mb-3">
						<InputGroup.Text>Description</InputGroup.Text>
						<Form.Control
							as="textarea"
							type="text"
							value={desc}
							onChange={(e) => {
								const value = e.target.value;
								setTransaction({ ...transaction, description: value });
								setSaveBtn(false);
								setClearBtn(false);
								setDesc(e.target.value);
							}}
							placeholder="description of transaction"
						/>
					</InputGroup>
					<Row>
						<Col sm={7}>
							<InputGroup>
								<InputGroup.Text className="mb-3">Category</InputGroup.Text>
								<Select
									defaultValue={catOptions[0]}
									value={catOptions.find(o => o.value === categorySelect) || null}
									options={catOptions}
									onChange={(e) => {
										const value = e.value;
										setTransaction({ ...transaction, category: value });
										setCategorySelect(value);
									}}
								/>
							</InputGroup>
						</Col>
						<Col sm={5}>
							<InputGroup>
								<InputGroup.Text>NTD</InputGroup.Text>
								<Form.Control
									className="input-amount"
									aria-label="Amount"
									type="number"
									value={amount}
									onChange={(e) => {
										const value = e.target.value;
										setTransaction({ ...transaction, amount: value });
										setAmount(e.target.value);
									}}
								/>
							</InputGroup>
						</Col>
					</Row>
				</Form>
			</Container >
		)
	}

	return (
		<>
			< div className='d-flex justify-content-start pb-3' >
				<Button variant='primary' size='sm' onClick={handleShow}><AiOutlinePlus className='icon-button' />&nbsp;Add Transaction</Button>
			</div >

			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Add Transaction</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{addData()}
				</Modal.Body>
				<Modal.Footer>
					<div className='d-flex justify-content-end'>
						<Stack direction="horizontal" gap={2}>
							<Button disabled={saveBtn} onClick={
								() => {
									SaveData();
								}} className="saveButton" variant="primary" size="sm">Save</Button>
							<Button disabled={clearBtn} onClick={ResetForm} variant="danger" size="sm">Clear</Button>
							<Button variant="danger" size="sm" onClick={handleClose}>
								Close
							</Button>
						</Stack>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default AddTransaction;