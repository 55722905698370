import { useState, useEffect } from 'react';
import React from 'react';
import axios from 'axios';
import { Container, Table, Row, Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { BiDotsHorizontalRounded, BiSearch, BiUpArrow, BiDownArrow } from 'react-icons/bi';
import MonthPicker from 'simple-react-month-picker';
import AddTransaction from './TransactionAdd';
import TransactionDelete from './TransactionDelete';
import TransactionEdit from './TransactionEdit';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MyDoc } from './pdf/pdfDoc';
import Button from 'react-bootstrap/Button';
import { AiOutlineDownload } from 'react-icons/ai';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router';
import { RiGitBranchFill, RiWhatsappLine } from 'react-icons/ri';


const URL_JIWI = 'https://ex.jiwi.tw'
const URL_HOST = process.env.REACT_APP_SERVER_IPADDR;

axios.defaults.baseURL = URL_HOST;

const TransactionList = () => {
	const [pageNo, setPage] = useState(0);
	const [transactions, setTransactions] = useState([]);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [pageCount, setPageCount] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
	const [sortOrder, setSortOrder] = useState([
		{ id: 0, name: 'id', order: 0 },
		{ id: 1, name: 'date', order: 0 },
		{ id: 2, name: 'category', order: 0 },
		{ id: 3, name: 'description', order: 0 }
	]);
	const [sortField, setSortField] = useState(0);
	const [totalSum, setTotalSum] = useState(0);
	const dataPerPage = 12;
	const pagesVisited = pageNo * dataPerPage;

	const [name, setName] = useState('');
	const [token, setToken] = useState(null);
	const [expire, setExpire] = useState('');
	const navigate = useNavigate();

	//console.log('Default sort order: ', sortOrder[0].order);

	const presetSelection = [
		{
			title: "Current Month",
			start: moment().startOf("month").toDate(),
			end: moment().endOf("month").toDate()
		},
		{
			title: "Past 3 months",
			start: moment().subtract(2, "month").startOf("month").toDate(),
			end: moment().endOf("month").toDate()
		},
		{
			title: "Past 6 months",
			start: moment().subtract(5, "month").startOf("month").toDate(),
			end: moment().endOf("month").toDate()
		},
		{
			title: "This Year",
			start: moment().startOf("year").toDate(),
			end: moment().endOf("year").toDate()
		},
		{
			title: "Previous Year",
			start: moment().subtract(1, "year").month(0),
			end: moment().subtract(1, "year").month(11)
		},
		{
			title: "All Transaction",
			start: moment().set('year', 2017),
			end: moment().endOf("year").toDate()
		}
	];

	useEffect(() => {
		refreshToken();
		setTransactions([]);
		setPageCount(0);
		getSelectedTransactions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMonth, sortOrder]);

	const refreshToken = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_SERVER_IPADDR + '/token');
			setToken(response.data.accessToken);
			const decoded = jwt_decode(response.data.accessToken);
			setName(decoded.name);
			setExpire(decoded.exp);
		} catch (error) {
			if (error.response) {
				navigate("/login");
			}
		}
	}

	const axiosJWT = axios.create();

	axiosJWT.interceptors.request.use(async (config) => {
		const currentDate = new Date();
		if (expire * 1000 < currentDate.getTime()) {
			const response = await axios.get(process.env.REACT_APP_SERVER_IPADDR + '/token');
			config.headers.Authorization = `Bearer ${response.data.accessToken}`;
			setToken(response.data.accessToken);
			const decoded = jwt_decode(response.data.accessToken);
			setName(decoded.name);
			setExpire(decoded.exp);
		}
		return config;
	}, (error) => {
		return Promise.reject(error);
	});

	const getSelectedTransactions = async () => {
		let startDate = moment().startOf("month").format("YYYY-MM-DD");
		let endDate = moment().endOf("month").format("YYYY-MM-DD");
		if (selectedMonth !== null) {
			startDate = moment(selectedMonth[0]).format("YYYY-MM-DD");
			endDate = moment(selectedMonth[1]).format("YYYY-MM-DD");
			/* 	console.log(startDate, endDate); */
		}

		try {
			/* 	console.log('Get Transactions from API'); */
			setIsLoading(true);
			/* const response = await axiosJWT.get(`/api/getTransactions?start=${startDate}&end=${endDate}&sortorder=${sortOrder[sortField].order}&sortfield=${sortField}`, { method: "GET" }); */
			const response = await axiosJWT.get('/api/getTransactions', {
				params: {
					start: startDate,
					end: endDate,
					sortorder: sortOrder[sortField].order,
					sortfield: sortField
				},
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			const data = response.data;
			setTransactions(data[0]);
			totalPages(data[0]);
			setIsLoading(false);
			setTotalSum(data[1][0].total);
			/* 		console.log(data); */
		} catch (err) {
			console.error(err);
		}
	};

	const totalPages = (value) => {
		setPageCount(Math.ceil(value.length / dataPerPage));
	}

	const addZero = (num, totalLength) => {
		return String(num).padStart(totalLength, '0');
	};

	const changePage = ({ selected }) => {
		setPage(selected);
	};

	const order = (index) => {
		/* console.log('typeof', typeof (index)); */
		let temparr = [...sortOrder];
		if (sortOrder[index].order === 1) {
			temparr[index] = { order: 0 };

		} else {
			temparr[index] = { order: 1 };
		}
		// Need to initial to default for sortOrder
		setSortOrder(temparr);
		setSortField(index);
		//setSortField(0);
		console.log('order:', sortOrder[index].order);
		console.log('field:', index);
	};

	const DisplayStatus = () => {
		return (
			<>
				<Row>
					<div className='transactions-total'>
						<label className='transactions-label'>
							TOTAL (NTD):
						</label>
						<label className='transactions-label-amount'>
							{new Intl.NumberFormat('en-US').format(totalSum)}
						</label>
					</div>
				</Row>
			</>
		)
	};

	return (
		<Container className='border-secondary pt-0 mt-0'>

			<Row className='d-flex transaction-tool-row'>
				<Col md="auto" className="transaction-search-col">
					<div className="transaction-search">
						Search
					</div>
					<div className="transaction-search-icon icon-button">
						<BiSearch />
					</div>
				</Col>
				<Col xs lg="2" className='transaction-date-col' >
					<div className='monthPicker'>
						<MonthPicker
							presets={presetSelection}
							highlightCol="#24b364"
							closeDelay={500}
							onChange={(d) => {
								setSelectedMonth(d);
								setPage(0);
							}}
						/>
					</div>
				</Col>
				<Col xs style={{
					display: 'flex', justifyContent: 'right'
				}}>
					<div className="pageTitle">Transactions history</div>
				</Col>

			</Row >

			{
				isLoading === true ? <div> <p>Loading...</p> </div> :
					transactions.length === 0 ?
						<div>
							<p>No Data</p>
						</div>
						:
						<div className='transactions-table'>
							<Table className='table table-hover stripped table-borderless' bordered={false} >
								<thead className='transactions-table-head'>
									<tr>
										<th>#</th>
										<th>
											Tx ID
											<button className="updown-button" onClick={() => order(0)}>{sortOrder[0].order === 0 ? <BiDownArrow className='icon-button' /> : <BiUpArrow className='icon-button' />}</button>
										</th>
										<th>
											Date
											<button className="updown-button" onClick={() => order(1)}>{sortOrder[1].order === 0 ? <BiDownArrow className='icon-button' /> : <BiUpArrow className='icon-button' />}</button>
										</th>
										<th>
											Category
										</th>
										<th>
											Description
											<button className="updown-button" onClick={() => order(3)}>{sortOrder[3].order === 0 ? <BiDownArrow className='icon-button' /> : <BiUpArrow className='icon-button' />}</button>
										</th>
										<th>Amount</th>
										<th>Actions</th>
									</tr>
								</thead>

								<tbody className='transactions-table-body'>
									{transactions.slice(pagesVisited, pagesVisited + dataPerPage).map((transaction, index) => (
										<tr key={transaction.id}>
											<td>{index + 1 + (pageNo * dataPerPage)}</td>
											<td>{addZero(transaction.id, 5)}</td>
											<td>{moment(new Date(transaction.date)).format("YYYY-MM-DD")}</td>
											<td>{transaction.catname}</td>
											<td>{transaction.description}</td>
											<td>{new Intl.NumberFormat('en-US').format(transaction.amount)}</td>
											<td>
												<div className='transaction-button-div'>
													<TransactionEdit data={transaction} />
													<TransactionDelete data={transaction} />
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</Table></div>
			}
			<Row>
				<DisplayStatus />
			</Row>
			<Row className="pt-2">
				<Col md="auto">
					<AddTransaction />
					{/* 	<PdfDocument data={{ transactions, totalSum }} /> */}
				</Col>
				<Col md='auto'>
					<PDFDownloadLink document={<MyDoc data={{ transactions, totalSum }} />} fileName="report.pdf">
						{({ blob, url, loading, error }) =>
							loading ? 'Loading document...' : <Button variant='primary' size='sm'><AiOutlineDownload className='icon-button' />&nbsp;Download</Button>
						}
					</PDFDownloadLink>
				</Col>
				<Col className='pagination-col' style={{
					display: 'flex', justifyContent: 'center'
				}}>
					<ReactPaginate
						previousLabel={<AiOutlineLeft className='icon-button' />}
						nextLabel={<AiOutlineRight className='icon-button' />}
						breakLabel={<BiDotsHorizontalRounded className='icon-button' />}
						pageRangeDisplayed={1}
						pageCount={pageCount}
						onPageChange={changePage}
						containerClassName={"paginationBttns"}
						previousLinkClassName={"previousBttn"}
						nextLinkClassName={"nextBttn"}
						disabledClassName={"paginationDisabled"}
						activeClassName={"paginationActive"}
						forcePage={pageNo < 1 ? 0 : pageNo}
						renderOnZeroPageCount={null}
					/>
				</Col>
			</Row>

		</Container >
	)
}

export default TransactionList