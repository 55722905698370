/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
	const [name, setName] = useState('');
	const [token, setToken] = useState(null);
	const [expire, setExpire] = useState('');
	const [users, setUsers] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		refreshToken();
		getUsers();
	}, []);

	const refreshToken = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_SERVER_IPADDR + '/token');
			setToken(response.data.accessToken);
			const decoded = jwt_decode(response.data.accessToken);
			setName(decoded.name);
			setExpire(decoded.exp);
			/* 		console.log(response.data.accessToken) */
		} catch (error) {
			if (error.response) {
				navigate("/login");
			}
		}
	}

	const axiosJWT = axios.create();

	axiosJWT.interceptors.request.use(async (config) => {
		const currentDate = new Date();
		if (expire * 1000 < currentDate.getTime()) {
			const response = await axios.get(process.env.REACT_APP_SERVER_IPADDR + '/token');
			config.headers.Authorization = `Bearer ${response.data.accessToken}`;
			setToken(response.data.accessToken);
			const decoded = jwt_decode(response.data.accessToken);
			setName(decoded.name);
			setExpire(decoded.exp);
		}
		return config;
	}, (error) => {
		return Promise.reject(error);
	});

	const getUsers = async () => {
		/* console.log('token:', token); */
		const response = await axiosJWT.get(process.env.REACT_APP_SERVER_IPADDR + '/users', {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
		/* 	console.log(response.data); */
		setUsers(response.data);
	}

	return (
		<div className="container mt-5">
			<h1>Welcome Back: {name}</h1>
			<table className="table is-striped is-fullwidth">
				<thead>
					<tr>
						<th>No</th>
						<th>Name</th>
						<th>Email</th>
					</tr>
				</thead>
				<tbody>
					{users.map((user, index) => (
						<tr key={user.id}>
							<td>{index + 1}</td>
							<td>{user.name}</td>
							<td>{user.email}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default Dashboard