import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useAuth } from '../api/AuthContext';

const Login = () => {
	const { setAuth } = useAuth();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [msg, setMsg] = useState('');
	const navigate = useNavigate();

	const Auth = async (e) => {
		e.preventDefault();
		try {
			await axios.post(process.env.REACT_APP_SERVER_IPADDR + '/login', {
				email: email,
				password: password
			});
			setAuth(true);
			navigate("/transaction");
		} catch (error) {
			if (error.response) {
				setMsg(error.response.data.msg);
			}
		}
	}

	return (
		<Container className=" d-flex justify-content-center">
			<Col xs={4} className="">
				<form onSubmit={Auth} className="box">
					<Row className="mt-5 text-start">
						<label className="label">Username</label>
						<input type="text" className="input" placeholder="username" value={email} onChange={(e) => setEmail(e.target.value)} />
					</Row>
					<Row className="mt-2 text-start">
						<label className="label">Password</label>
						<input type="password" className="input" autoComplete="off" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" value={password} onChange={(e) => setPassword(e.target.value)} />
					</Row>
					<Row className="mt-4">
						<Button className="primary" onClick={Auth}>Login</Button>
					</Row>
					<Row>
						<p className="has-text-centered mt-4">{msg}</p>
					</Row>
				</form>
			</Col>
		</Container>
	)
}

export default Login