import axios from 'axios';

export const axiosApi = axios.create({
	/* baseURL: 'https://ex.jiwi.tw/api' */
	baseURL: process.env.REACT_APP_SERVER_IPADDR + '/api'
});

export const SaveTransaction = async (data) => {
	console.log('**** SaveTransaction');
	try {
		const res = await axiosApi.post(`/saveData`, {
			date: data.date,
			category: data.category,
			description: data.description,
			amount: data.amount
		});
		/* 		console.log('Response:', res); */
	} catch (err) {
		console.error(err);
	}
}

export const DeleteTransaction = async (data) => {
	console.log('**** DeleteTransaction');
	/* console.log(data); */

	try {
		const res = await axiosApi.post(`/deleteData`, {
			txid: data
		});
		/* 		console.log('Response:', res); */
	} catch (err) {
		console.error(err);
	}
}

export const UpdateTransaction = async (data) => {
	console.log('**** SaveTransaction');
	/* 	console.log(data);
		console.log('txid', data.id);
		console.log('date', data.date);
		console.log('category', data.category_id);
		console.log('description', data.description);
		console.log('amount', data.amount); */
	try {
		const res = await axiosApi.post(`/updateData`, {
			txid: data.id,
			date: data.date,
			category: data.category_id,
			description: data.description,
			amount: data.amount
		});
		/* 	console.log('Response:', res); */
	} catch (err) {
		console.error(err);
	}
}