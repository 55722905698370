import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';

// Defined pages
import UserList from './components/UserList';
import MenuBar from './components/MenuBar';
import ErrorPage from './components/ErrorPage';
import ProfilePage from './components/ProfilePage';
import Dashboard from './components/DashBoard';
import TransactionList from './components/TransactionList';
import Login from './components/Login';
import Register from './components/Register';
import Settings from './components/Settings';
import { useAuth } from './api/AuthContext';

function App() {
	const { auth } = useAuth();
	return (
		<div className="App">
			<Router>
				<MenuBar />
				<Routes>
					<Route exact path="/login" element={<Login />} />
					<Route exact path="#dashboard" element={<UserList />} />
					<Route path="/register" element={<Register />} />
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/transaction" element={<TransactionList />} />
					<Route path="/settings" element={<Settings />} />
					<Route path="*" element={<ErrorPage />} />
				</Routes>
			</Router>
		</div >
	);
}

export default App;
