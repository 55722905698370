import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Col, Row, Button } from "react-bootstrap";
import { RiProjectorFill } from 'react-icons/ri';

const Settings = () => {
	const [content, setContent] = useState(null);

	useEffect(() => {
		ContentRender();
	}, [content]);

	const handleSelect = (eventKey) => {
		console.log(eventKey, typeof (eventKey));
		setContent(eventKey);
	};

	const ContentRender = () => {
		if (content === null) return;
		switch (content) {
			case '1':
				return (
					<label className='settings-content-label'>PROFILE</label>
				);
			case '2':
				return (
					<label className='settings-content-label'>USERS</label>
				);
			case '3':
				return (
					<label className='settings-content-label'>CATEGORY</label>
				);
			case '4':
				return (
					<label className='settings-content-label'>CATEGORY TYPE</label>
				);
			case '5':
				return (
					<label className='settings-content-label'>STATUS TYPE</label>
				)
		}
	};

	return (
		<Container className="d-flex">
			<Col sm={3} className="pb-5">
				<Row>
					<label className="settings-label">SETTINGS</label>
				</Row>
				<Nav defaultActiveKey="#" className="flex-column" onSelect={handleSelect}>
					<Nav.Link eventKey="1">Profile</Nav.Link>
					<Nav.Link eventKey="2">Users</Nav.Link>
					<Nav.Link eventKey="3">Category</Nav.Link>
					<Nav.Link eventKey="4">Category Type</Nav.Link>
					<Nav.Link eventKey="5">Status Type</Nav.Link>
				</Nav>
				<Row>
					<label></label>
				</Row>
			</Col>
			<Col className=''>
				<ContentRender />
			</Col>
		</Container>
	)
}

export default Settings;