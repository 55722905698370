import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { Container, Row, Col, Form, InputGroup, Stack } from 'react-bootstrap';

import { DeleteTransaction } from '../api/axios';
import { RiDeleteBinLine } from 'react-icons/ri';

// to be replace by mysql call
const catOptions = [
	{ value: 1, label: 'Food' },
	{ value: 2, label: 'Transport' },
	{ value: 3, label: 'Household' },
	{ value: 4, label: 'Others' }
];

const TransactionDelete = (value) => {
	const [show, setShow] = useState(false);
	const [item, setItem] = useState([]);
	const handleClose = () => {
		setShow(false);
		window.location.reload();
	};
	const handleShow = () => {
		setShow(true);
		setItem(value.data);
		console.log(item);
		console.log(value.data);
	};

	const showItem = () => {
		return (
			<Container>
				<Row className="modal-row-head">
					<Col xs={1}>TXID</Col>
					<Col xs={3}>DATE</Col>
					<Col xs={1}>CAT</Col>
					<Col>DESCRIPTION</Col>
					<Col xs={2}>AMOUNT</Col>
				</Row>
				<Row className="modal-row-body">
					<Col xs={1}>{item.id}</Col>
					<Col xs={3}>{moment(item.date).format("YYYY-MM-DD")}</Col>
					<Col xs={1}>{item.catname}</Col>
					<Col>{item.description}</Col>
					<Col xs={2}>{item.amount}</Col>
				</Row>
			</Container>
		)
	};

	const deleteItem = (itemId) => {
		console.log('**** deleteItem');
		console.log('Item to delete:', itemId);
		DeleteTransaction(itemId);
		handleClose();
	};

	return (
		<>
			< div className='d-flex justify-content-start' >
				<button className='transaction-delete-button' data-toggle='tooltip' data-placement='top' title='Delete' variant='primary' size='sm' onClick={(e) => handleShow(e)}><RiDeleteBinLine className='icon-button' /></button>
			</div >

			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Delete Transaction</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{showItem()}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={() => deleteItem(item.id)} variant="danger" size="sm">Delete</Button>
					<Button variant="success" size="sm" onClick={handleClose}>
						Cancel
					</Button>

				</Modal.Footer>
			</Modal>
		</>
	);
}

export default TransactionDelete;